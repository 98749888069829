import React from 'react';
import { Link } from "react-router-dom";
import WhiteLogo from '../assets/logo-text-white.svg';
import { FaFacebookF, FaTwitter, FaYoutube } from "react-icons/fa";

export default class Footer extends React.Component {
	render() {
		return (
			<div className="section footer-section">
				<div className="container">
					<div className="footer-wrapper">
						<div className="footer-top">
							<div className="footer-title-text">Unlock potential. <br />Deliver excellence.

							</div>
						</div>
						<div className="footer-main-grid">
							<div className="footer-link-menu">
								<div className="bold-text text-white">Product</div>
								<Link className="footer-link" to="/" exact="true">Overview</Link>
								<Link className="footer-link" to="/features">Features</Link>
								<Link className="footer-link" to="/integrations">Integrations</Link>
								<Link className="footer-link" to="/pricing">Pricing</Link>
								<Link className="footer-link" to="/demo">Schedule a demo</Link>
							</div>
							<div className="footer-link-menu">
								<div className="bold-text text-white">Solutions</div>
								<Link className="footer-link" to="/sd-mobile">Technician app</Link>
								<Link className="footer-link" to="/sd-cyberoffice">Online scheduling</Link>
								<Link className="footer-link" to="/sd-revenuebuilder">Recurring Service</Link>
								<Link className="footer-link" to="/sd-dealer">Over the counter sales</Link>
								<Link className="footer-link" to="/hosting">Server hosting</Link>
							</div>
							<div className="footer-link-menu">
								<div className="bold-text text-white">Resources</div>
								<a className="footer-link" href="https://help.rossware.com">Help center</a>
								<Link className="footer-link" to="/connect">Connect for Support</Link>
								<Link className="footer-link" to="/contact">Contact us</Link>
								<a className="footer-link" target="_blank" rel="noreferrer" href="https://rossware.betteruptime.com">Platform status</a>
							</div>
							<div className="footer-link-menu">
								<div className="bold-text text-white">Company</div>
								<a className="footer-link" href="tel:800-353-4101">800 353 4101</a>
								<a className="footer-link" href="mailto:hello@rossware.net">hello@rossware.net</a>
								<Link className="footer-link" to="/about">About us</Link>
								<a className="footer-link" href="https://blog.rossware.com">Rossware Blog</a>
								<Link className="footer-link" to="/faq">FAQs</Link>
							</div>
						</div>
						<div className="footer-bottom">
							<div>
								<img src={WhiteLogo} width="120px" alt="Rossware Logo" className="footer-logo mr-4" />
								<Link to="/terms" className="footer-link footer-bottom-link">Terms of Use</Link>
								<Link to="/privacy-policy" className="footer-link footer-bottom-link">Privacy Policy</Link>
								<span className="footer-link footer-bottom-link">&#169; {new Date().getFullYear()}, Fullsteam Software Holdings LLC. All Rights Reserved.</span>
							</div>
							<div>
								<a className="d-inline-block footer-link rw-footer-link-light mr-4" href="https://www.facebook.com/RosswareInc"><FaFacebookF /></a>
								<a className="d-inline-block footer-link rw-footer-link-light mr-4" href="https://twitter.com/rosswareinc"><FaTwitter /></a>
								<a className="d-inline-block footer-link rw-footer-link-light mr-0" href="https://www.youtube.com/user/RosswareInc"><FaYoutube /></a>
							</div>
						</div>
					</div>
				</div>
			</div>


		)
	}
}