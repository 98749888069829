import React from 'react';
import { Link, NavLink } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Logo from '../../assets/main-logo.svg'
import { Button } from 'react-bootstrap';
// import onClickOutside from "react-onclickoutside";
import { NavDropdown } from 'react-bootstrap';
import { BsCalendarWeek } from "react-icons/bs";
import moment from 'moment';

class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			showFeaturesDropdown: false,
			showSolutionsDropdown: false,
			showResourcesDropdown: false,
			navExpanded: false
		};
	}

	renderAnnouncementBanner() {
		const currentDate = moment();
		const eventDate = moment('2024-09-23');
		if (currentDate < eventDate) {
			return (
				<div style={{ fontWeight: '500', fontSize: '12px', textAlign: 'center', color: '#fff', backgroundColor: '#00204D', paddingTop: '8px', paddingBottom: '8px' }}>Join us September 23 - 26 in Portsmouth, VA for <a style={{ textDecoration: 'underline' }} href="https://mms.psaworld.org/members/evr/reg_event.php?evid=37513857&orgcode=PROS">PSA Convention 2024</a>! &nbsp; Get $50 off registration using discount code: "ROS"</div>
			)
		}
		return null;
	}

	componentDidMount() {
		window.addEventListener('scroll', () => {
			let activeClass = '';
			if (window.scrollY > 20) {
				activeClass = 'scrolled';
			}
			this.setState({ activeClass });
		});
	}

	componentWillUnmount() {
		const _fnEmptyFunctionPointer = () => { };
		window.removeEventListener('scroll', _fnEmptyFunctionPointer);
	}

	setNavExpanded = (expanded) => {
		this.setState({ navExpanded: expanded });
	}

	closeNav = () => {
		this.setState({ navExpanded: false });
	}

	render() {
		return (
			<div className="navigation-wrapper">
				<Navbar expanded={this.state.navExpanded} collapseOnSelect onToggle={this.setNavExpanded} variant="light" expand="lg" className={`rw-nav ${this.state.activeClass}`}>
					<div className="container rw-inner-nav">
						<Nav.Link eventkey="1" as={NavLink} to="/" exact className="p-0 my-0 mr-4" onClick={this.closeNav}><Navbar.Brand><img src={Logo} width="160px" alt="Rossware Logo" className="crisp-image"></img></Navbar.Brand></Nav.Link>
						<Navbar.Toggle />
						<Navbar.Collapse className="position-relative">
							<Nav className="mr-auto">
								<NavDropdown title="Product">
									<NavDropdown.Item as={NavLink} to="/" exact onClick={this.closeNav}>
										Overview
										<div>Manage calls, jobs, schedule, and more</div>
									</NavDropdown.Item>
									<NavDropdown.Item as={NavLink} to="/features" onClick={this.closeNav}>
										Features
										<div>See all the powerful features</div>
									</NavDropdown.Item>
									<NavDropdown.Item as={NavLink} to="/integrations" onClick={this.closeNav}>
										Integrations
										<div>Automate with integrations</div>
									</NavDropdown.Item>
									<NavDropdown.Item as={NavLink} to="/rosswarepay" onClick={this.closeNav}>
										Payment processing
										<div>RosswarePay - Integrated processing with great rates</div>
									</NavDropdown.Item>
									<NavDropdown.Item as={NavLink} to="/demo" onClick={this.closeNav} className="border text-center mt-2">
										<BsCalendarWeek className="mr-1 mt-n1" /> Get a demo
									</NavDropdown.Item>
								</NavDropdown>
								<NavDropdown title="Addons">
									<NavDropdown.Item as={NavLink} to="/sd-mobile" onClick={this.closeNav}>
										Technician mobile app
										<div>SD-Mobile is for techs in the field</div>
									</NavDropdown.Item>
									<NavDropdown.Item as={NavLink} to="/sd-cyberoffice" onClick={this.closeNav}>
										Online scheduling
										<div>SD-CyberOffice has tons  of automations</div>
									</NavDropdown.Item>
									<NavDropdown.Item as={NavLink} to="/sd-revenuebuilder" onClick={this.closeNav}>
										Recurring service
										<div>Manage recurring service with SD-RevenueBuilder</div>
									</NavDropdown.Item>
									<NavDropdown.Item as={NavLink} to="/sd-dealer" onClick={this.closeNav}>
										Over the counter sales
										<div>SD-Dealer manages in person sales and inventory</div>
									</NavDropdown.Item>
									<NavDropdown.Item as={NavLink} to="/hosting" onClick={this.closeNav}>
										Server hosting
										<div>Let us manage the complexities of server hosting for you</div>
									</NavDropdown.Item>
									
								</NavDropdown>
								<NavDropdown title="Resources">
									<NavDropdown.Item as={NavLink} to="/contact" onClick={this.closeNav}>Contact us</NavDropdown.Item>
									<NavDropdown.Item as={NavLink} to="/connect" onClick={this.closeNav}>Connect for support</NavDropdown.Item>
									<NavDropdown.Item href="https://help.rossware.com" onClick={this.closeNav}>Help center</NavDropdown.Item>
									<NavDropdown.Item href="https://resources.rossware.com/downloads" onClick={this.closeNav}>Downloads</NavDropdown.Item>
									<NavDropdown.Item href="https://blog.rossware.com" onClick={this.closeNav}>Update blog</NavDropdown.Item>
								</NavDropdown>

								<Nav.Link eventkey="21" exact as={NavLink} className="nav-link" to="/pricing" onClick={this.closeNav}>Pricing</Nav.Link>
							</Nav>
							<Nav className="ml-auto position-relative">
								<Nav.Link eventkey="223" className="nav-link" href="https://dashboard.rossware.com" onClick={this.closeNav}>Sign in</Nav.Link>
								<Link eventkey="25" as={NavLink} className="btn btn-sm btn-primary mr-1" to="/demo">Get a demo</Link>
								<Button eventkey="26" as={NavLink} className="btn btn-sm btn-light mb-lg-0 ml-lg-2 d-flex align-items-center" to="/sd-order" onClick={this.closeNav}>Sign up</Button>
							</Nav>
						</Navbar.Collapse>
					</div>
				</Navbar>
				{ this.renderAnnouncementBanner() }
			</div>

		)
	}
}

export default Navigation;
